<template>
  <nav class="d-flex flex-row justify-center align-center">
    <v-container class="navbar d-flex">
      <v-row class="navbar-row">
        <v-col cols="12" md="6" sm="4" class="nav-cell nav-left">
          <a class="brand" href="#"><img src="../assets/logo_ko_w.svg" alt="brand-logo" /></a>
        </v-col>
        <v-col cols="12" md="6" sm="8" class="nav-cell nav-right">
          <ul class="social-btns">
            <li class="text-btn"><a class="zendesk" href="https://support.dietfriends.kr/hc/ko" target="_blank">고객 센터</a></li>
            <li class="text-btn"><a class="store" href="https://smartstore.naver.com/dietfriends" target="_blank">공식 스토어</a></li>
            <li class="social-btn"><a class="icon-outer insta" href="https://www.instagram.com/balancefriends/" target="_blank"><font-awesome-icon :icon="['fab', 'instagram']"></font-awesome-icon></a></li>
            <li class="social-btn"><a class="icon-outer facebook" href="https://www.facebook.com/dietfriends.official/?ref=py_c" target="_blank"><font-awesome-icon :icon="['fab', 'facebook-f']"></font-awesome-icon></a></li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </nav>
</template>

<script>
  export default {
      name: "DfNavbar"
  }
</script>

<style lang="scss" scoped>
  @import '../variables.scss';
  nav {
    display: block;
    position: relative;
    z-index: 999;
    height: 100px;
    .navbar {
      box-shadow: none !important;
      background-color: transparent;
      padding: 0;
      height: auto;

      .navbar-row {
        padding: $pagePadding;
      }
      .nav-cell {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .nav-left {
        justify-content: flex-start;
        .brand {
          font-size: 4em;
          font-family: $brandFont;
          color: $primaryBlueColor;
          text-decoration: none;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2rem 0;
          img {
            width: auto;
            height: 28px;
          }
        }
      }
      .nav-right {
        justify-content: flex-end;
        .social-btns {
          display: flex;
          flex-direction: row;
          padding: 0;
          justify-content: center;
          align-items: center;
        }
        .text-btn {
          margin-right: 40px;
          a {
            color: white;
            font-size: 20px;
            line-height: 24px;
            text-decoration: none;
            font-family: $basicFont;
          }
          :hover {
            border-bottom: 1px solid white;
          }
        }
        .social-btn {
          margin-right: 1rem;
          font-size: 2.2em;
          color: white;
          border-radius: 1.1em;
        }
        .social-btn:hover {
          transition: ease-in-out 0.2s;
          .icon-outer {
            background-color: $primaryBlueColor;
          }
        }
        .social-btn:last-child {
          margin-right: 0;
        }
        .icon-outer {
          width: 1em;
          border-radius: 0.6em;
          height: 1em;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: transparent;

          svg {
            color: white;
            font-size: 0.5em;
          }
        }
        .icon-outer.insta {
          svg {
            font-size: 0.7em;
          }
        }
      }
    }
  };
</style>
