<template>
  <div class="banner-wrapper" v-bind:class="{ 'mobile': $vuetify.breakpoint.smAndDown }">
    <div id="info-banner" v-if="isInfoClose">
      <div class="container">
        <div class="banner-text-box">
          <p class="banner-text">
            다이어트 프렌즈가 <strong>밸런스 프렌즈로 바뀌었어요!</strong>
          </p>
        </div>
        <span class="banner-btn-box">
        <a class="banner-btn" @click="calcBannerHeight('info')"><font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon></a>
      </span>
      </div>
    </div>
    <div id="top-banner" v-if="isClose && !$vuetify.breakpoint.smAndDown">
      <div class="container">
        <div class="banner-text-box">
          <img class="banner-icon" src="../assets/app_icon@2x.png" alt="app_icon"/>
          <p class="banner-text">
            람희에게 다이어트 코칭을 받고싶다면, <strong>지금 바로 다운로드 하세요!</strong>
          </p>
          <v-btn elevation="0" class="go-store" @click="goDeepLinkUrl">다운로드</v-btn>
        </div>
        <span class="banner-btn-box">
        <a class="banner-btn" @click="calcBannerHeight('down')"><font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon></a>
      </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TopBanner',
  props: {
  },
  data: () => ({
    isClose: true,
    currentHeight: 140,
    isInfoClose: true,
    deepLinkUrl: 'https://dietfriends.link/C35rDyW397',
  }),
  methods: {
    goDeepLinkUrl: function () {
      this.$ma.trackEvent({action: '딥링크 클릭 [탑배너]'});
      return window.open(this.deepLinkUrl, '_blank');
    },
    calcBannerHeight: function (type) {
      if (type === 'down') {
        this.$ma.trackEvent({action: '탑배너 닫기'});
        this.currentHeight -= 70;
        this.$emit('bannerHeight', this.currentHeight)
        this.isClose = false;
      } else {
        this.$ma.trackEvent({action: '인포배너 닫기'});
        this.currentHeight -= 70;
        this.$emit('bannerHeight', this.currentHeight)
        this.isInfoClose = false;
      }
    },
  },
  computed: {
  }
}
</script>
<style lang="scss" scoped>
@import '../variables.scss';

.banner-wrapper {
  width: 100%;
  position: relative;
}
#top-banner, #info-banner {
  position: relative;
  width: 100%;
  height: $bannerHeight;
  padding: 0 1rem;
  background-color: #fff;
  align-items: center;
  z-index: 99999;
  .container {
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
  }
  .banner-icon {
    width: 32px;
    height: 32px;
  }
  .banner-text-box {
    width: auto;
    z-index: 99;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .banner-text {
    width: auto;
    margin: 0;
    font-size: 22px;
    line-height: $bannerHeight;
    font-family: $basicFont;
    color: $basicFontColor;
    text-decoration: none;
    padding-left: 12px;
  }
  .go-store {
    background: $hardBlueColor;
    color: white;
    font-family: $basicFont;
    font-size: 18px;
    font-weight: bold;
    padding: 4px 24px 2px 24px;
    margin-left: 36px;
    border-radius: 24px;
  }
  .go-store:focus {
    outline: none;
  }
  .banner-btn-box {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: $bannerHeight;
    margin: auto;
    z-index: 999;
  }
  .banner-btn {
    font-size: 1em;
    line-height: $bannerHeight;
    padding: 0 1rem;
    color: $basicFontColor;
  }
}
#info-banner {
  background:#FFEB89;

  .banner-text-box {
    justify-content: flex-start;
    align-items: center;
  }
}
.mobile {
  #info-banner {
    background:#FFEB89;
    height: auto;

    .banner-text-box {
      justify-content: flex-start;
      align-items: center;
      min-height: 70px;

      .banner-text {
        width: auto;
        margin: 0;
        font-size: 13px;
        line-height: 15px;
        font-family: $basicFont;
        color: $basicFontColor;
        text-decoration: none;
        padding-left: 12px;
      }
    }
  }
}
</style>
