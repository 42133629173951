<template>
  <v-app v-bind:class="{ 'mobile': $vuetify.breakpoint.smAndDown }">
    <div class="df-loader" v-if="!isLoadedDone">
      <span class="loader-box">
        <img src="./assets/loader.gif" alt="loader"/>
      </span>
    </div>
    <TopBanner @bannerHeight="getBannerHeight" />
    <DfNavbar v-if="!$vuetify.breakpoint.smAndDown" />
    <v-main>
      <Home/>
      <Feature />
      <FeatureDetail />
      <ReferenceCard />
      <Review />
      <Characters />
      <Last />
      <FooterBanner />
    </v-main>
    <DfFooter />
    <div id="main-bg" v-bind:style="{ top: bannerHeight }"></div>
  </v-app>
</template>

<script>
import Home from './components/Home';
import Last from "./components/Last";
import Review from "./components/Review";
import FeatureDetail from "./components/FeatureDetail";
import Feature from "./components/Feature";
import DfNavbar from './components/DfNavbar';
import DfFooter from './components/DfFooter';
import ReferenceCard from "@/components/ReferenceCard";
import Characters from "@/components/Characters";
import FooterBanner from "@/components/FooterBanner";
import TopBanner from "@/components/TopBanner";

export default {
  name: 'App',
  components: {
    TopBanner,
    FooterBanner,
    Characters,
    ReferenceCard,
    Home, DfNavbar, DfFooter,
    Feature,
    FeatureDetail,
    Review,
    Last
  },
  data: function () {
    return {
      isLoadedDone: true,
      bannerHeight: '140px',
    }
  },
  mounted() {
    this.$ma.trackEvent({action: 'Session Started'});
    console.log(this.bannerHeight);
  },
  methods: {
    getBannerHeight (val) {
      this.bannerHeight = val + 'px';
    }
  },
  computed: {
  }
};
</script>
<style lang="scss">
  @import './fonts.scss';
  @import './variables.scss';
  @import './media.scss';
  @import './animation.scss';


  /* GO Main Styles*/
  html, body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  ul, ol {
    list-style: none;
    padding: 0;
  }

  p {
    margin: 0;
  }

  .page {
    width: 100%;
    height: auto;
    display: block !important;
    position: relative !important;
    z-index: 9;
  }
  .page-inner {
    padding: $pagePadding;
  }
  .container {
    padding: 0;
  }
  .container-inner {
    padding: 0 130px;
    height: 100%;
    width: 100%;
  }

  .row {
    margin: 0;
  }

  #main-bg {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 800px;
    background: url('./assets/main_bg@2x.png') center center no-repeat;
    background-size: cover;
    z-index: 0;
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -o-transition: all .2s linear;
    -ms-transition: all .2s linear;
    transition: all .2s linear;
  }
  .df-loader {
    position: fixed;
    background: $defaultBackgroundColor;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    .loader-box {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .mobile {
    .page-title {
      font-size: 36px !important;
      line-height: 52px !important;
      p {
        font-size: 36px !important;
        line-height: 52px !important;
      }
    }
    html, body {
      overflow-x: hidden;
    }
    #main-bg {
      display: none;
      height: 1058px !important;
    }
  }


</style>
