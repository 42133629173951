<template>
  <div class="page" id="last" v-bind:class="{ 'mobile' : $vuetify.breakpoint.smAndDown}">
    <div class="last-body">
      <v-container class="last-container">
        <v-row class="last-row page-inner d-flex flex-row justify-center align-end">
          <div class="ramhee-trial">
              <div class="mockup-box">
                <img class="mockup" src="../assets/main_mockup@2x.png" alt="mockup" @load="readyToShowMockup"/>
              </div>
            <div class="screen-inner d-flex flex-column align-center justify-start">
              <div class="bubble-box">
                <p class="bubble-text">다이어트<br/>꿀팁!</p>
              </div>
              <div class="msg-box">
                <ul class="msgs">
                  <li class="msg" v-for="(msg, idx) in currentDialog.msgs" v-bind:key="idx">
                    <v-row v-if="idx === 0">
                      <span class="ramhee-thumb-box"><span class="ramhee-thumb"></span></span>
                      <p class="ramhee-name">람희</p>
                    </v-row>
                    <v-row v-if="currentDialogIndex !== dialogs.length">
                      <p class="ramhee-msg">{{msg}}</p>
                    </v-row>
                  </li>
                  <li class="msg" v-if="currentDialogIndex === dialogs.length">
                    <v-row class="end-msg">
                      <img src="../assets/smile.png" alt="ramhee" @load="readyToShowRamhee"/>
                      <p class="ramhee-msg" v-if="showLastMsgBox">당연하죠! 람희는 다이어트 박사니까요</p>
                      <p class="ramhee-msg" v-if="showLastMsgBox">얼른 오세요 :) 람희가 알고 있는 모든걸 알려줄게요!</p>
                    </v-row>
                  </li>
                </ul>
              </div>
              <div class="answer-box">
                <ul class="answers" v-if="(currentDialogIndex !== dialogs.length)">
                  <li class="answer" v-for="(q, idx) in currentDialog.answers" v-bind:key="idx" @click="goNextMsg(currentDialogIndex+1)">{{q}}</li>
                </ul>
                <ul class="answers" v-if="currentDialogIndex === dialogs.length">
                  <li class="answer end-answer animate__animated animate__heartBeat animate__infinite"><a id="goToSee" href="#" @click.prevent="goDeepLinkUrl">람희 만나러 가기</a></li>
                </ul>
              </div>
              <span class="trial-obj-box" v-if="!$vuetify.breakpoint.smAndDown">
              </span>
            </div>
          </div>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
    export default {
        name: "Last",
        data: function () {
          return {
              showLast: false,
              showThisAnswer: false,
              showMockup: false,
              showMsgBox: false,
              showLastMsgBox: false,
              currentDialog: {},
              currentDialogIndex: 0,
              deepLinkUrl: 'https://dietfriends.link/C35rDyW397',
              dialogs: [{
                  msgs: ['람희가 다이어트에 관한 간단한 몇 가지 꿀팁을 알려주려고 해요', '참고로! 모든 꿀팁은 한국영양학회를 기준으로 이야기 하는거니 람희 이야기를 믿어도 좋아요!', '무슨 꿀팁이 있는지 한 번 보겠어요?'],
                  answers: ['응 알려줘!'],
              }, {
                  msgs: ['아이스크림은 너무 맛있어요! 그치만 ㅠㅠ 아이스크림은 지방과 당이 많아 칼로리가 굉장히 높아요! 다이어트 중에는 최대한 먹지 않는게 좋죠 ㅠㅠ', '그래도 람희가 알려주는대로 한다면 다이어트 중에도 먹을 수 있는 방법이 있어요!'],
                  answers: ['그게 뭐야?? 알려줘!'],
              }, {
                  msgs: ['아이스크림이 꼬옥 먹고싶다면 과일과 요거트를 간 후, 냉동고에 3~4시간 넣어 아이스크림으로 만들어 봐요! 상큼하고 건강한 맛을 즐길수 있답니다!', '하지만 요거트 아이스크림도 너무 많이먹으면 체중이 증가할 수 있으니 1~2주에 1번 정도만 먹어줘요!'],
                  answers: ['고마워! 다른 꿀팁도 있어?'],
              }],
          }
        },
        mounted() {
        },
        created() {
            this.currentDialog = this.dialogs[0];
            this.currentDialogIndex = 0;
        },
        methods: {
          getThumbUrl(pic) {
            return require('../assets/'+pic)
          },
          goNextMsg(nextIndex) {
            if(nextIndex === 1) {
              this.$ma.trackEvent({action: '꿀팁 클릭'})
            }
            if(nextIndex > this.dialogs.length) return false;
            if(nextIndex !== this.dialogs.length) {
              this.currentDialog = this.dialogs[nextIndex];
            }
            this.currentDialogIndex = nextIndex;
          },
          readyToShowMockup() {
              this.showMsgBox = true;
          },
          readyToShowRamhee() {
              this.showLastMsgBox = true;
          },
          goDeepLinkUrl: function () {
            this.$ma.trackEvent({action: '딥링크 클릭 [꿀팁]'});
            return window.open(this.deepLinkUrl, '_blank');
          },
        },
        components: {}
    }
</script>

<style scoped lang="scss">
  @import '../variables';
  #last {
    background: $defaultBackgroundColor;
    width: 100%;
    height: 800px;
    display: block;
    position: relative;

    .last-body {
      position: relative;
      display: block;
      height: 100%;
      width: 100%;
    }
    .last-container {
      height: 100%;
      position: relative;
      .last-row {
        position: relative;
        height: 100%;
        overflow: hidden;
          .ramhee-trial {
            position: relative;
            width: 700px;
            height: 700px;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: center;
          }
          .msg-box {
            z-index: 200;
            width: 464px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            .msgs {
              padding-left: 28px;
              flex-direction: column;
              display: flex;
              justify-content: center;
              align-items: center;
              .msg {
                width: 100%;
                margin: 0;
                .row {
                  margin: 0;
                }
                .ramhee-thumb-box {
                  width: 60px;
                  height: 60px;
                  background: white;
                  border-radius: 19px;
                  overflow: hidden;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: 0.5px solid #707070;
                  margin-bottom: 12px;
                }
                .ramhee-thumb {
                  width: 60px;
                  height: 60px;
                  background: url('../assets/ramhee_thumb.png') no-repeat center center;
                  background-size: cover;
                }
                .ramhee-name {
                  margin-left: 8px;
                  font-size: 19px;
                  line-height: 23px;
                  margin-bottom: 0;
                  font-family: $basicFont;
                  color: black;
                  display: flex;
                  align-items: center;
                }
                .ramhee-msg {
                  margin-left: 36px;
                  margin-right: 36px;
                  margin-bottom: 8px;
                  padding: 18px 24px 18px 24px;
                  border: 1.5px solid $msgBorderColor;
                  background: white;
                  border-radius: 1px 20px 20px 20px;
                  text-align: justify;
                  font-size: 20px;
                  font-family: $basicFont;
                  font-weight: bold;
                  line-height: 30px;
                }
                .end-msg {
                  img {
                    width: auto;
                    height: 100px;
                    margin-top: 0.5rem;
                    margin-left: 1rem;
                  }
                }
              }
            }
          }
          .answer-box {
            position: absolute;
            width: 464px;
            bottom: 56px;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 999;
            display: flex;
            justify-content: center;
            align-items: center;
            .answers {
              width: 400px;
              padding: 0;
              flex-direction: column;
              display: flex;
              justify-content: center;
              align-items: center;
              .answer {
                display: inline-block;
                padding:  14px 40px 12px 40px;
                background: white;
                border: 2px solid black;
                border-radius: 24px;
                cursor: pointer;
                font-size: 24px;
                line-height: 30px;
                font-family: $basicFont;
                color: black;
                font-weight: bold;
              }
              .answer.end-answer {
                border: 1px solid $primaryBlueColor;
                background: $primaryBlueColor;
                a {
                  margin: 0;
                  padding: 0;
                  color: white;
                  text-decoration: none;
                  font-family: $basicFont;
                }
              }
              .answer:hover {
                background: $primaryBlueColor;
                color: white;
                transition: ease-in-out 0.1s;
              }
            }
          }
        .screen-inner {
          width: 526px;
          height: 648px;
          position: relative;

          .bubble-box {
            margin-top: -86px;
            width: 150px;
            height: 172px;
            background: url('../assets/speech_bubble.png') center center no-repeat;
            background-size: cover;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 99;

            .bubble-text {
              font-size: 28px;
              line-height: 36px;
              font-family: $basicFont;
              color: white;
              font-weight: bold;
              margin: 0 0 16px 8px;
            }
          }

          .trial-obj-box {
            position: absolute;
            bottom: 108px;
            left: -204px;
            margin: auto;
            width: 300px;
            height: 268px;
            background: url('../assets/ramhee_smart@2x.png') center center no-repeat;
            background-size: contain;
          }
        }
          .mockup-box {
            position: absolute;
            top: 52px;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 526px;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            overflow: hidden;

            img {
              width: 526px;
              height: auto;
              z-index: 99;
            }
          }
      }
    }
  }
  #last.mobile {
    background: $defaultBackgroundColor;
    width: 100%;
    height: auto !important;
    display: block;
    position: relative;

    .last-body {
      position: relative;
      display: block;
      height: 100%;
      width: 100%;
    }
    .last-container {
      height: 100%;
      position: relative;
      .last-row {
        position: relative;
        height: 100%;
        overflow: hidden;
        padding: 96px 0 0 0;
        .ramhee-trial {
          position: relative;
          width: 400px;
          height: 700px;
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          justify-content: center;
        }
        .msg-box {
          z-index: 200;
          width: 364px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          .msgs {
            padding-left: 28px;
            flex-direction: column;
            display: flex;
            justify-content: center;
            align-items: center;
            .msg {
              width: 100%;
              margin: 0;
              .row {
                margin: 0;
              }
              .ramhee-thumb-box {
                width: 60px;
                height: 60px;
                background: white;
                border-radius: 19px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 0.5px solid #707070;
                margin-bottom: 12px;
              }
              .ramhee-thumb {
                width: 60px;
                height: 60px;
                background: url('../assets/ramhee_thumb.png') no-repeat center center;
                background-size: cover;
              }
              .ramhee-name {
                margin-left: 8px;
                font-size: 19px;
                line-height: 23px;
                margin-bottom: 0;
                font-family: $basicFont;
                color: black;
                display: flex;
                align-items: center;
              }
              .ramhee-msg {
                margin-left: 36px;
                margin-right: 36px;
                margin-bottom: 8px;
                padding: 8px 16px 12px 16px;
                text-align: justify;
                line-height: 30px;
                font-size: 16px;
              }
            }
          }
        }
        .answer-box {
          position: absolute;
          width: 400px;
          bottom: 56px;
          left: 0;
          right: 0;
          margin: auto;
          z-index: 999;
          display: flex;
          justify-content: center;
          align-items: center;
          .answers {
            width: 400px;
            padding: 0;
            flex-direction: column;
            display: flex;
            justify-content: center;
            align-items: center;
            .answer {
              padding:  10px 16px 8px 16px;
              cursor: pointer;
              font-size: 16px;
              line-height: 30px;
              font-family: $basicFont;
              color: black;
              font-weight: bold;
            }
            .answer.end-answer {
              border: 1px solid $primaryBlueColor;
              background: $primaryBlueColor;
              a {
                margin: 0;
                padding: 0;
                color: white;
                text-decoration: none;
              }
            }
            .answer:hover {
              background: $primaryBlueColor;
              color: white;
              transition: ease-in-out 0.1s;
            }
          }
        }
        .screen-inner {
          width: 526px;
          height: 648px;
          position: relative;

          .bubble-box {
            margin-top: -86px;
            width: 150px;
            height: 172px;
            background: url('../assets/speech_bubble.png') center center no-repeat;
            background-size: cover;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 99;

            .bubble-text {
              font-size: 28px;
              line-height: 36px;
              font-family: $basicFont;
              color: white;
              font-weight: bold;
              margin: 0 0 16px 8px;
            }
          }

          .trial-obj-box {
            position: absolute;
            bottom: 108px;
            left: -204px;
            margin: auto;
            width: 300px;
            height: 268px;
            background: url('../assets/ramhee_smart@2x.png') center center no-repeat;
            background-size: contain;
          }
        }
        .mockup-box {
          position: absolute;
          top: 52px;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          width: 400px;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          overflow: hidden;

          img {
            width: 400px;
            height: auto;
            z-index: 99;
          }
        }
      }
    }
  }
</style>
