<template>
  <div id="characters" class="page" v-bind:class="{ 'mobile': $vuetify.breakpoint.smAndDown }">
    <v-container class="page-container">
      <div class="page-inner">
      <v-col class="page-col d-flex flex-column justify-center align-center">
          <div class="page-title-box">
            <p class="page-title">코칭 캐릭터 소개</p>
          </div>
          <ul class="character-list">
            <li class="character-name">
              <a class="name-text" v-bind:class="{ 'active': currentSlide === 0 }" href="#" @click.prevent="slideTo(0)">람희</a>
            </li>
            <li class="character-name">
              <a class="name-text" v-bind:class="{ 'active': currentSlide === 1 }" href="#" @click.prevent="slideTo(1)">장세다</a>
            </li>
          </ul>
        <div class="character-card-wrapper">
          <swiper ref="characterSwiper" :options="swiperOptions">
            <swiper-slide>
              <div class="swiper-inner d-flex flex-row justify-space-between align-center">
                <div class="character-img-box">
                  <img class="character-img" src="../assets/cheerup.png" alt="character-img">
                </div>
                <div class="character-desc-box d-flex flex-column justify-space-between align-start">
                  <p class="desc-name">
                    람희
                  </p>
                  <p class="desc-text">
                    날개 없는 하늘다람쥐 람희. 날개가 없는 탓에 무리와
                    어울리지 못하고 혼자서 상수리나무 밑동에서 살고 있다.
                    건강에 관련된 지식을 쌓으며 외로운 마음을 달래는 건강 너드.
                  </p>
                  <ul class="desc-tags">
                    <li class="desc-tag">#다정한다이어트코칭</li>
                    <li class="desc-tag">#건강너드</li>
                    <li class="desc-tag">#하늘다람쥐</li>
                  </ul>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="swiper-inner d-flex flex-row justify-space-between align-center">
                <div class="character-img-box">
                  <img class="character-img" src="../assets/jang.png" alt="character-img">
                </div>
                <div class="character-desc-box d-flex flex-column justify-space-between align-start">
                  <p class="desc-name">
                    장세다
                  </p>
                  <p class="desc-text">
                    빈말하는 걸 싫어하는 프로팩폭러 장세다.
                    과거 잘못된 다이어트로 힘들어했다가
                    건강한 다이어트의 가치를 알게 된후 감량에 성공했다.
                    자신의 경험을 바탕으로 올바른 다이어트를 돕는다.
                  </p>
                  <ul class="desc-tags">
                    <li class="desc-tag">#빡센다이어트코칭</li>
                    <li class="desc-tag">#불광동불벅지</li>
                    <li class="desc-tag">#은근정있음</li>
                  </ul>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </v-col>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Characters',
  data() {
    let that = this;
    return {
      currentSlide: 0,
      swiperOptions: {
        initialSlide: 0,
        loop: false,
        on: {
          slideChangeTransitionStart: (e) => {
            that.currentSlide = e.activeIndex;
          }
        }
      }
    };
  },
  methods: {
    slideTo: function(index) {
      this.swiper.slideTo(index);
    },
  },
  computed: {
    swiper() {
      return this.$refs.characterSwiper.$swiper;
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../variables';
#characters {
  height: 800px;
  width: 100%;
  background: #F3F3F3;

  .page-container,
  .page-inner {
    height: 100%;
  }
  .page-col {
    width: 100%;
    height: 100%;
    padding: 0;

    .page-title {
      font-size: 52px;
      line-height: 72px;
      color: black;
      font-weight: bold;
      font-family: $basicFont;
    }
    .character-list {
      margin-top: 30px;
      margin-bottom: 52px;
      padding: 0;
      li {
        display: inline-block;
      }
      .character-name {
        padding: 0 28px;
        position: relative;
        .name-text {
          text-decoration: none;
          font-size: 24px;
          line-height: 32px;
          font-family: $basicFont;
          font-weight: normal;
          color: black;
          opacity: 0.5;
        }
        .name-text.active {
          opacity: 1;
          font-weight: bold;
          color: black;
        }
      }
      .character-name:not(:last-child):after {
        content: '';
        width: 1.5px;
        height: 14px;
        background: black;
        opacity: 0.5;
        position: absolute;
        top: -4px;
        bottom: 0;
        right: 0;
        margin: auto;
      }
    }
    .character-card-wrapper {
      width: 1000px;
      height: 424px;
      background: white;
      border-radius: 32px;
      box-shadow: 0 5px 20px rgba(0,0,0,0.04);

      .swiper-container {
        height: 100%;
      }
      .swiper-slide {
        height: 100%;
      }
      .swiper-inner {
        height: 100%;
        padding: 0 100px;

        .character-img-box {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .character-img {
          width: 285px;
          height: auto;
        }
        .character-desc-box {
          width: 435px;
          height: auto;
          .desc-name {
            font-size: 40px;
            line-height: 60px;
            font-family: $basicFont;
            color: black;
            font-weight: bold;
            margin: 0;
          }
          .desc-text {
            font-size: 18px;
            line-height: 32px;
            font-family: $basicFont;
            color: black;
            word-break: keep-all;
            margin: 25px 0 35px 0;
          }
          .desc-tags {
            padding: 0;
            li {
              display: inline-block;
              font-size: 18px;
              line-height: 36px;
              font-weight: bold;
              font-family: $basicFont;
              margin-right: 32px;
            }
            li:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
};
#characters.mobile {
  height: auto;
  width: 100%;
  padding: $mobileSectionPadding;
  background: #F3F3F3;

  .page-container,
  .page-inner {
    height: 100%;
  }
  .page-col {
    width: 100%;
    height: 100%;
    padding: 0;
    .character-card-wrapper {
      width: 320px;
      height: auto;

      .swiper-container {
        height: 100%;
      }
      .swiper-slide {
        height: 100%;
      }
      .swiper-inner {
        height: 100%;
        padding: 32px;
        flex-direction: column !important;

        .character-img-box {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 32px;
        }
        .character-img {
          width: 160px;
          height: auto;
        }
        .character-desc-box {
          width: 100%;
          height: auto;
          padding: 32px;
        }
      }
    }
  }
};
</style>
