<template>
  <div class="footer" v-bind:class="{ 'mobile' : $vuetify.breakpoint.smAndDown }">
    <div class="container">
      <v-col class="footer-col">
        <div class="footer-nav">
          <v-col cols="12" md="6" sm="12" class="nav-cell nav-left">
            <a class="brand" href="#"><img src="../assets/logo_ko.svg" alt="brand-logo" /></a>
          </v-col>
          <v-col cols="12" md="6" sm="12" class="nav-cell nav-right">
            <ul class="social-btns">
              <li class="text-btn"><a class="zendesk" href="https://support.dietfriends.kr/hc/ko" target="_blank">고객 센터</a></li>
              <li class="text-btn"><a class="store" href="https://smartstore.naver.com/dietfriends" target="_blank">공식 스토어</a></li>
              <li class="social-btn"><a class="icon-outer insta" href="https://www.instagram.com/balancefriends/" target="_blank"><font-awesome-icon :icon="['fab', 'instagram']"></font-awesome-icon></a></li>
              <li class="social-btn"><a class="icon-outer facebook" href="https://www.facebook.com/dietfriends.official/?ref=py_c" target="_blank"><font-awesome-icon :icon="['fab', 'facebook-f']"></font-awesome-icon></a></li>
            </ul>
          </v-col>
        </div>
        <div class="footer-info">
          <p class="corp-name">(주)열정팩토리</p>
          <ul class="infos" v-if="!$vuetify.breakpoint.smAndDown">
            <li class="corp-info"><p>서울시 강남구 학동로 161, 건일빌딩 3,4F</p></li>
            <li class="corp-info"><p>사업자등록번호 114-87-18004</p></li>
            <li class="corp-info"><p>통신판매업 2020-서울서초-0815</p></li>
          </ul>
          <ul class="infos" v-if="!$vuetify.breakpoint.smAndDown">
            <li class="corp-info"><p>대표 이정훈</p></li>
            <li class="corp-info"><p>고객문의 contact@dietfriends.kr</p></li>
            <li class="corp-info"><p>유선문의 070-7756-1070 (10:00 ~ 19:00)</p></li>
          </ul>
          <ul class="infos" v-if="$vuetify.breakpoint.smAndDown">
            <li class="corp-info"><p>서울시 강남구 학동로 161, 건일빌딩 3,4F</p></li>
            <li class="corp-info"><p>사업자등록번호 114-87-18004</p></li>
            <li class="corp-info"><p>통신판매업 2020-서울서초-0815</p></li>
            <li class="corp-info"><p>대표 이정훈</p></li>
            <li class="corp-info"><p>고객문의 contact@dietfriends.kr</p></li>
            <li class="corp-info"><p>유선문의 070-7756-1070 (10:00 ~ 19:00)</p></li>
          </ul>
        </div>
        <div class="footer-info">
          <ul class="infos">
            <li class="corp-info"><a class="test" href="https://page.balancefriends.com/policy/privacy" target="_blank">개인정보처리방침</a></li>
            <li class="corp-info"><a class="test" href="https://page.balancefriends.com/policy/terms" target="_blank">서비스 이용약관</a></li>
          </ul>
        </div>
        <p class="copyright">2023 © All Rights Reserved</p>
      </v-col>
    </div>
  </div>
</template>

<script>
    export default {
        name: "DfFooter"
    }
</script>

<style scoped lang="scss">
  @import '../variables';
  .footer {
    background: $footerBackgroundColor;
    display: block;
    position: relative;
    flex-direction: column;
    padding: 54px 0;
    border-top: 1px solid #ececec;

    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .footer-col {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      width: 100%;
      padding: $pagePadding;

      .footer-nav {
        width: 100%;
        display: flex;
        flex-direction: row;
      }

      .nav-cell {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .nav-left {
        justify-content: flex-start;
        .brand {
          font-size: 4em;
          font-family: $brandFont;
          color: $primaryBlueColor;
          text-decoration: none;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2rem 0;
          img {
            width: auto;
            height: 28px;
          }
        }
      }
      .nav-right {
        justify-content: flex-end;
        .social-btns {
          display: flex;
          flex-direction: row;
          padding: 0;
          justify-content: center;
          align-items: center;
        }
        .text-btn {
          margin-right: 40px;
          a {
            color: #616161;
            font-size: 20px;
            line-height: 24px;
            text-decoration: none;
            font-family: $basicFont;
          }
          :hover {
            border-bottom: 1px solid #616161;
          }
        }
        .social-btn {
          margin-right: 1rem;
          font-size: 2.2em;
          color: #616161;
          border-radius: 1.1em;
        }
        .social-btn:last-child {
          margin-right: 0;
        }
        .icon-outer {
          width: 1em;
          border-radius: 0.6em;
          height: 1em;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: transparent;

          svg {
            color: #616161;
            font-size: 0.5em;
          }
        }
        .icon-outer.insta {
          svg {
            font-size: 0.7em;
          }
        }
      }
    }
    .footer-info {
      margin-top: 44px;
      width: 100%;
      display: flex;
      flex-direction: column;
      .corp-name {
        font-size: 20px;
        line-height: 40px;
        color: black;
        font-weight: bold;
        font-family: $basicFont;
        margin: 0;
      }
      .infos {
        padding: 0;
        margin-top: 6px;

        .corp-info {
          display: inline-block;
          position: relative;
          padding-right: 24px;

          p {
            display: inline-block;
            margin: 0;
            font-size: 20px;
            line-height: 40px;
            color: #616161;
            font-family: $basicFont;
            font-weight: normal;
          }
        }
        .corp-info:not(:first-child) {
          margin-left: 24px;
        }
        .corp-info:not(:last-child):after {
          content: '';
          width: 1.5px;
          height: 14px;
          background: black;
          opacity: 0.5;
          position: absolute;
          top: -4px;
          bottom: 0;
          right: 0;
          margin: auto;
        }
      }
    }
    .copyright {
      margin: 44px 0 0 0;
      text-align: left;
      width: 100%;
      font-size: 20px;
      line-height: 40px;
      color: #616161;
      font-family: $basicFont;
      font-weight: normal;
    }
  }
  .footer.mobile {
    background: $footerBackgroundColor;
    display: block;
    position: relative;
    flex-direction: column;
    padding: 54px 0;
    border-top: 1px solid #ececec;

    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .footer-col {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      width: 100%;
      padding: $pagePadding;

      .footer-nav {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
      }
      .nav-left {
        justify-content: flex-start;
      }
      .nav-right {
        justify-content: flex-start;
        .text-btn {
          a {
            font-size: 16px;
          }
        }
      }
    }
    .footer-info {
      margin-top: 44px;
      width: 100%;
      display: flex;
      flex-direction: column;

      .infos {
        padding: 0;
        margin-top: 6px;

        .corp-info {
          display: block;
          position: relative;
          padding-right: 12px;

          p {
            display: inline-block;
            margin: 0;
            font-size: 12px;
            line-height: 20px;
            color: #616161;
            font-family: $basicFont;
            font-weight: normal;
          }
        }
        .corp-info:not(:first-child) {
          margin-left: 0px;
        }
        .corp-info:not(:last-child):after {
          display: none;
        }
      }
    }
  }
</style>
