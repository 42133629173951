<template>
  <div class="page" id="feature" v-bind:class="{ 'mobile' : $vuetify.breakpoint.smAndDown }">
    <div class="feature-body">
      <v-container>
        <v-row class="feature-row page-inner">
          <v-col cols="12" sm="6" class="feature-left">
            <span class="mockup-box" v-if="showMockup" ref="mockupBox"><img class="mockup" src="../assets/w_mockup_frame.png" alt="w-mockup" @load="readyToShowMockup"/></span>
            <span class="mockup-bg" v-if="showMockup"><img class="oval-bg" src="../assets/oval_bg@2x.png" alt="w-mockup-bg" @load="readyToShowMockup"/></span>
            <span class="mockup-shadow" v-if="showMockup"></span>
            <div class="video-box" v-if="showVideo" ref="videoBox">
              <video @canplay="updatePaused" autoplay muted ref="videoTarget" v-bind:class="{ show : showMockup && isVideoReady }" playsinline>
                <source src="../assets/looknfeel.mp4" type="video/mp4">
                <source src="../assets/looknfeel.webm" type="video/webm">
              </video>
            </div>
            <img src="../assets/loader.gif" class="loader" v-if="showMockup && !isVideoReady" alt="loader"/>
          </v-col>

          <v-col cols="12" sm="6" class="feature-right" v-if="showDesc">
            <div class="right-inner">
              <p class="feature-title">
                다이어트 고민, <br/>이젠 끝!
              </p>
              <p class="feature-desc">
                이렇게 먹어도 될까? 어떻게 먹어야 할까<br/>챙길 것도, 알아야 할 것도 너무 많은 다이어트!
                <br/>
                똑똑한 앱 밸런스 프렌즈가 모두 알려주니까<br/> 더 이상 다이어트 걱정은 노놉!!
              </p>
              <ul class="video-btns" v-if="showCtaBtns && !$vuetify.breakpoint.smAndDown">
                <li class="video-btn"><a class="video-control" href="#" @click.prevent="playDf"><font-awesome-icon :icon="['fas', 'play']"></font-awesome-icon></a></li>
                <li class="video-btn"><a class="video-control" href="#" @click.prevent="pauseDf"><font-awesome-icon :icon="['fas', 'pause']"></font-awesome-icon></a></li>
                <li class="video-btn"><a class="video-control" href="#" @click.prevent="replayDf"><font-awesome-icon :icon="['fas', 'undo']"></font-awesome-icon></a></li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
    export default {
        name: "Feature",
        props: [],
        data: function () {
            return {
                videoElement: null,
                paused: null,
                showMockup: true,
                showCtaBtns: true,
                showDesc: true,
                showVideo: true,
                isVideoReady: false,
                mockupBoxWidth: 0,
                videoBoxHeight: 0,
                videoBoxWidth: 0,
            }
        },
        watch: {
            isVideoReady: function (newVal) {
                newVal ? this.startPlay() : false;
            }
        },
        methods: {
            updatePaused (e) {
              console.log(e);
                this.videoElement = e.target;
                if(this.$refs.mockupBox.clientWidth) {
                    this.mockupBoxWidth = this.$refs.mockupBox.clientWidth;
                }
                this.videoElement.muted = true;
                this.isVideoReady = true;
            },
            playDf() {
              this.$ma.trackEvent({action: '룩앤필 [재생]'})
                this.videoElement.play();
            },
            pauseDf() {
              this.$ma.trackEvent({action: '룩앤필 [정지]'})
                this.videoElement.pause();
            },
            replayDf() {
              this.$ma.trackEvent({action: '룩앤필 [되감기]'})
                this.videoElement.load();
                this.videoElement.play();
            },
            startPlay() {
                if(this.isVideoReady) {
                  this.videoElement.play();
                  this.showCtaBtns = true;
                }
            },
            async readyToShowMockup() {
                this.showVideo = true;
            }
        },
    }
</script>

<style scoped lang="scss">
@import '../variables';
#feature {
  display: block;
  position: relative;

  .feature-body {
    height: 100%;
    .container {
      height: 100%;

      .row {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: 0;
      }
      .page-inner {
        min-height: $basicSectionHeight;
      }
      .feature-left {
        position: relative;
        padding: 3rem 0;
        height: 100%;
        width: 100%;

        .mockup-box {
          position: relative;
          width: 323px;
          height: 633px;
          float: left;
          z-index: 100;
          margin-left: 98px;

          img {
            width: 323px;
            height: 633px;
            z-index: 100;
          }
        }
        .mockup-bg {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          z-index: 0;
          width: 520px;
          height: 520px;

          img {
            z-index: 1;
            width: 520px;
            height: 520px;
          }
        }
        .mockup-shadow {
          box-shadow: 0 0 40px 0 #2D2F4A;
          border-radius: 47px;
          width: 303px;
          height: 633px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 108px;
          margin: auto;
        }
        .loader {
          z-index: 999;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          margin: auto;
        }
        .video-box {
          position: absolute;
          top: 0;
          left: 122px;
          bottom: 0;
          margin: auto;
          width: 275px;
          height: 486px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          z-index: 200;

          video {
            position: relative;
            width: 275px;
            height: 486px;
            z-index: 200;
            display: none;
            background: white;
            border: 1px solid #EBECED;
          }
          video.show {
            display: block;
          }
        }
      }
      .feature-right {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column;
        padding: 0;

        .right-inner {
          position: relative;
          display: block;
          width: 403px;
          height: 100%;
        }
        .feature-title {
          font-size: 52px;
          font-family: $basicFont;
          font-weight: 700;
          line-height: 72px;
          color: black;
          margin: 0;
        }
        .feature-desc {
          font-size: 18px;
          line-height: 32px;
          color: black;
          font-family: $basicFont;
          margin-top: 56px;
          margin-bottom: 60px;
        }
        .video-btns {
          position: relative;
          padding: 0 30px;
          border: 1px solid rgba(0,0,0,0.1);
          border-radius: 31px;
          width: 270px;
          height: 62px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .video-btn {
            position: relative;
            display: inline-block;
            text-align: center;
            width: 36px;
            height: 36px;

            .video-control {
              display: inline-block;
              text-decoration: none;
              font-size: 24px;
              color: black;
            }
            .video-control:hover {
              color: rgba(0,0,0,0.2);
              transition: ease-in-out 0.1s;
            }
          }
        }
      }
    }
  }
}
#feature.mobile {
  padding: $mobileSectionPadding;
  .feature-body {
    .container {

      .page-inner {
        min-height: $basicSectionHeight;
      }
      .feature-left {
        position: relative;
        padding: 0;
        order: 2;
        display: flex;
        justify-content: center;
        align-items: center;

        .mockup-box {
          position: relative;
          width: 323px;
          height: 633px;
          float: left;
          z-index: 100;
          margin: 0;

          img {
            width: 323px;
            height: 633px;
            z-index: 100;
          }
        }
        .mockup-bg {
          right: 0;
          width: 300px;
          height: 300px;

          img {
            z-index: 1;
            width: 300px;
            height: 300px;
          }
        }
        .mockup-shadow {
          box-shadow: 0 0 40px 0 #2D2F4A;
          border-radius: 47px;
          width: 303px;
          height: 633px;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
        }
        .video-box {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          margin: auto;
          width: 275px;
          height: 486px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          z-index: 200;

          video {
            position: relative;
            width: 275px;
            height: 486px;
            z-index: 200;
            display: none;
            background: white;
            border: 1px solid #EBECED;
          }
          video.show {
            display: block;
          }
        }
      }
      .feature-right {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 64px;

        .right-inner {
          position: relative;
          display: block;
          width: 336px;
          height: 100%;
        }
        .feature-title {
          font-size: 36px;
          font-family: $basicFont;
          font-weight: 700;
          line-height: 52px;
        }
        .feature-desc {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
