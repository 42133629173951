<template>
  <div class="page" id="featureDetail" v-bind:class="{ 'mobile' : $vuetify.breakpoint.smAndDown }">
    <div class="featureDetail-body">
      <v-container class="body-inner">
        <v-row class="page-inner">
          <v-col cols="12" sm="6" class="desc-box">
            <div class="desc-inner">
              <div class="page-title">
                <p class="title-text">
                  다이어터를 위한<br/>손쉬운<br/><strong>식단 관리</strong>
                </p>
              </div>
              <div class="page-nav">
                <ul class="featureDetail-menus">
                  <li class="featureDetail-menu" v-for="(title, index) in featureSlides" v-bind:class="{ active : featureDetailSlideIndex === index }" v-bind:key="title" @click="moveTo(index)">{{title}}</li>
                </ul>
              </div>
              <swiper ref="descSwiper" :options="descSwiperOptions">
                <swiper-slide class="desc-slide slide-1" v-for="(desc,index) in descs" v-bind:key="index">
                  <div class="desc-slide-inner d-flex justify-center align-start flex-column">
                    <p class="desc-title" v-html="desc.title"></p>
                    <p class="desc-text" v-html="desc.text"></p>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="img-box">
            <swiper ref="screenSwiper" :options="swiperOptions" :auto-update="true">
              <swiper-slide><img class="screen" src="../assets/screens/food_recommendation.png" alt="screen" /></swiper-slide>
              <swiper-slide><img class="screen" src="../assets/screens/feedback_card.png" alt="feedback-card" /></swiper-slide>
              <swiper-slide><img class="screen" src="../assets/screens/nutrition_graph.png" alt="nutrition-graph" /></swiper-slide>
              <swiper-slide><img class="screen" src="../assets/screens/food_recommendation.png" alt="food-recommendation" /></swiper-slide>
              <swiper-slide><img class="screen" src="../assets/screens/feedback_card.png" alt="screen" /></swiper-slide>
            </swiper>
          </v-col>
        </v-row>
        <v-row class="page-inner second">
          <v-col cols="12" sm="6" class="img-box">
            <swiper ref="secondScreenSwiper" :options="secondSwiperOptions">
              <swiper-slide><img class="screen" src="../assets/screens/dummy.png" alt="screen" /></swiper-slide>
              <swiper-slide><img class="screen" src="../assets/screens/cheerful_msg.png" alt="cheerful-msg" /></swiper-slide>
              <swiper-slide><img class="screen" src="../assets/screens/info_msg.png" alt="information-msg" /></swiper-slide>
              <swiper-slide><img class="screen" src="../assets/screens/curated_msg.png" alt="curation-msg" /></swiper-slide>
              <swiper-slide><img class="screen" src="../assets/screens/dummy.png" alt="screen" /></swiper-slide>
            </swiper>
          </v-col>
          <v-col cols="12" sm="6" class="desc-box second">
            <div class="desc-inner">
              <div class="page-title">
                <p class="title-text">
                  나를 위한<br/>응원과 위로,<br/><strong>다이어트 정보</strong>
                </p>
              </div>
              <div class="page-nav">
                <ul class="featureDetail-menus">
                  <li class="featureDetail-menu" v-for="(title, index) in featureSecondSlides" v-bind:class="{ active : featureDetailSecondSlideIndex === index }" v-bind:key="title" @click="secondMoveTo(index)">{{title}}</li>
                </ul>
              </div>
              <swiper ref="secondDescSwiper" :options="secondDescSwiperOptions" :auto-update="true">
                <swiper-slide class="desc-slide slide-1" v-for="(desc,index) in secondDescs" v-bind:key="index">
                  <div class="desc-slide-inner d-flex justify-center align-start flex-column">
                    <p class="desc-title" v-html="desc.title"></p>
                    <p class="desc-text" v-html="desc.text"></p>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
    export default {
        name: "FeatureDetail",
        props: [],
        watch: {
        },
        data: function () {
          let that = this;
          return {
            featureDetailSlideIndex: 0,
            featureDetailSecondSlideIndex: 0,
            featureSlides: ['피드백 카드', '영양소 그래프', '식단 추천'],
            featureSecondSlides: ['멘탈 케어', '다이어트 정보', '맞춤 대화'],
            descs: [{
               title: '혼자는 어려운 식습관 개선, 도와줄게요',
               text: '매 끼니마다 내가 먹은 음식의 칼로리와 영양소를 분석해 \n' +
                   '내 식사에서 칭찬할 부분과 주의할 부분을 콕 집어서 알려줘요.',
            }, {
                title: '하루 영양소, 얼마나 먹었는지 알려줄게요',
                text: '영양소 그래프를 통해 칼로리와 3대 영양소는 물론,\n' +
                    '당과 나트륨,  물의 적정 섭취량까지 한눈에 알 수 있어요',
            }, {
                title: '매일 샐러드만 먹기 힘들다면 추천해 줄게요',
                text: '오늘 먹은 음식을 말해주면 다이어트 칼로리를 지키면서 \n' +
                    '필수 영양소도 꼭 채워주는 건강한 식단을 추천해드려요.',
            }],
            secondDescs: [{
              title: '힘든 다이어트, 포기하고 싶을 때 응원할게요',
              text: '다이어트 성과가 보이지 않아 지칠때 밸런스 프렌즈와 대화해 보세요.\n' +
                  '따뜻한 격려와 응원은 물론 해결 방법까지 알려준답니다. ',
            }, {
              title: '다이어트 상식, 바로 알고 싶을 때 물어보세요',
              text: '너무 많은 정보와 방법들에 다이어트 시작도 전에 지치셨나요?\n' +
                  '한국영양학회의 기준을 바탕으로 제대로 된 다이어트 정보만 알려줄게요.',
            }, {
              title: '갑작스런 상황, 해결책이 필요할 때 대화하세요',
              text: '어제 짜게 먹었나요? 갑자기 외식을 하게 되었나요?\n' +
                  '그럴 때 대화를 나누면 내 상황에 필요한 조언과 정보를 알려줘요.',
            }],
            swiperOptions: {
                initialSlide: 0,
                loop: false,
                slidesPerView: 3,
                slidesPerGroup: 1,
                slidesPerColumnFill: 'row',
                loopFillGroupWithBlank: true,
                on: {
                    slideChangeTransitionStart: (e) => {
                        that.featureDetailSlideIndex = e.activeIndex;
                        that.descSwiper.slideTo(e.activeIndex);
                    }
                }
            },
            descSwiperOptions: {
                initialSlide: 0,
                loop: false,
                resistance: false,
                on: {
                    slideChangeTransitionStart: (e) => {
                        that.featureDetailSlideIndex = e.activeIndex;
                        that.swiper.slideTo(e.activeIndex);
                    }
                }
            },
            secondSwiperOptions: {
              loop: false,
              initialSlide: 0,
              slidesPerView: 3,
              slidesPerGroup: 1,
              loopFillGroupWithBlank: true,
              on: {
                slideChangeTransitionStart: (e) => {
                  that.featureDetailSecondSlideIndex = e.activeIndex;
                  that.secondDescSwiper.slideTo(e.activeIndex);
                }
              }
            },
            secondDescSwiperOptions: {
              initialSlide: 0,
              loop: false,
              resistance: false,
              on: {
                slideChangeTransitionStart: (e) => {
                  that.featureDetailSecondSlideIndex = e.activeIndex;
                  that.secondSwiper.slideTo(e.activeIndex);
                }
              }
            },
          }
        },
        methods: {
          moveTo: function(index) {
            this.swiper.slideTo(index);
            this.descSwiper.slideTo(index);
          },
          secondMoveTo: function(index) {
            this.secondSwiper.slideTo(index);
            this.secondDescSwiper.slideTo(index);
          }
        },
        computed: {
            swiper() {
                return this.$refs.screenSwiper.$swiper;
            },
            descSwiper() {
                return this.$refs.descSwiper.$swiper;
            },
            secondSwiper() {
              return this.$refs.secondScreenSwiper.$swiper;
            },
            secondDescSwiper() {
              return this.$refs.secondDescSwiper.$swiper;
            }

        },
        mounted() {
            this.$vuetify.breakpoint.mobile ? this.swiper.allowTouchMove = true : this.swiper.allowTouchMove = false;
        }
    }
</script>

<style lang="scss" scoped>
@import '../variables.scss';
#featureDetail {
  width: 100%;
  display: block;
  position: relative;

  .featureDetail-body {
    position: relative;
    height: 100%;
    width: 100%;

    .body-inner {
      height: 100%;
      position: relative;

      .page-inner {
        min-height: $basicSectionHeight;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
    .swiper-container{
      width: 100%;
      position: relative;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      display: flex;
      overflow: hidden;
    }

    .img-box {
      width: 100%;
      height: 453px;
      position: relative;
      padding: 0;
      overflow: visible;
      .swiper-slide {
        position: relative;
        opacity: 0.5;
        margin-top: -40px;
        height: 550px;
        z-index: 9;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        display: flex;
        background: transparent;

        img {
          height: 371px;
          width: 214px;
          border: 1px solid #DDDDDD;
          border-radius: 12px;
        }
      }
      .swiper-slide.swiper-slide-next {
        opacity: 1;
        z-index: 99;
        justify-content: center;
        img {
          width: 261px;
          height: 453px;
          box-shadow: 10px 28px 20px rgba(0,0,0,0.12);
        }
      }
      .swiper-slide.swiper-slide-next + div {
        justify-content: flex-end;
        margin-right: 50px;
        visibility: visible !important;
      }
      .swiper-slide.swiper-slide-active {
      }
      .swiper-slide:not(.swiper-slide-active):not(.swiper-slide-next) {
        visibility: hidden;
      }
    }
    .desc-box.second {
      align-items: flex-end;
    }
    .desc-box {
      height: 100%;
      position: relative;
      display: flex;
      padding: 0;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .desc-inner {
        width: 400px;
        height: 100%;
      }

      .page-title {
        .title-text {
          font-size: 52px;
          line-height: 72px;
          color: black;
          font-family: $basicFont;
        }
      }

      .page-nav {
        margin: 46px 0;
        display: block;
        position: relative;
      }
      .featureDetail-menus {
        display: block;
        position: relative;
        padding-left: 0;

        .featureDetail-menu {
          font-size: 21px;
          line-height: 24px;
          font-family: $basicFont;
          font-weight: normal;
          padding: 4px 12px 2px 12px;
          color: rgba(0,0,0,0.3);
          cursor: pointer;
          display: inline-block;
        }
        .active {
          color: white;
          font-weight: bold;
          background: black;
          border-radius: 15px;
        }
      }
      .desc-slide {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        .desc-title {
          font-size: 21px;
          line-height: 32px;
          font-weight: bold;
          font-family: $basicFont;
          color: $basicFontColor;
          margin-bottom: 32px;
        }
        .desc-text {
          word-break: keep-all;
          max-width: 400px;
          color: $basicFontColor;
          font-family: $basicFont;
          font-size: 16px;
          width: 100%;
          line-height: 28px;
          text-align: justify;
        }
      }
    }
  }
}
#featureDetail.mobile {
  padding: $mobileSectionPadding;

  .featureDetail-body {
    position: relative;
    height: 100%;
    width: 100%;

    .body-inner {
      height: 100%;
      position: relative;

      .page-inner {
        min-height: $basicSectionHeight;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      .page-inner.second {
        margin-top: 72px;
        .img-box {
          order: 2;
        }
      }
    }
    .swiper-container{
      width: 100%;
      position: relative;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      display: flex;
      overflow: hidden;
    }

    .img-box {
      margin: 72px 0;
    }

    .desc-box.second {
      align-items: center;
    }
    .desc-box {
      height: 100%;
      position: relative;
      display: flex;
      padding: 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .desc-inner {
        width: 100%;
        height: 100%;
      }

      .page-title {
        .title-text {
          font-size: 36px;
          line-height: 52px;
          color: black;
          font-family: $basicFont;
        }
      }

      .page-nav {
        margin: 46px 0;
        display: block;
        position: relative;
      }
      .featureDetail-menus {
        display: block;
        position: relative;
        padding-left: 0;

        .featureDetail-menu {
          font-size: 12px;
          line-height: 24px;
          font-family: $basicFont;
          font-weight: normal;
          padding: 4px 12px 2px 12px;
          color: rgba(0,0,0,0.3);
          cursor: pointer;
          display: inline-block;
        }
        .active {
          color: white;
          font-weight: bold;
          background: black;
          border-radius: 15px;
        }
      }
      .desc-slide {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        .desc-title {
          font-size: 16px;
          line-height: 32px;
          font-weight: bold;
          font-family: $basicFont;
          color: $basicFontColor;
          margin-bottom: 32px;
        }
        .desc-text {
          word-break: keep-all;
          max-width: 300px;
          color: $basicFontColor;
          font-family: $basicFont;
          font-size: 14px;
          width: 100%;
          line-height: 28px;
          text-align: justify;
        }
      }
    }
  }
}
</style>
