import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import 'animate.css';
import Zendesk from '@dansmaculotte/vue-zendesk'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faInstagram, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import {
  faChevronLeft,
  faChevronRight,
  faPause,
  faPlay,
  faQuestion,
  faStar,
  faStore, faTimes,
  faUndo
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueAwesomeSwiper from "vue-awesome-swiper";
import 'swiper/css/swiper.css';
import {VueMasonryPlugin} from "vue-masonry";
import VueMultianalytics from 'vue-multianalytics'
library.add(faInstagram, faFacebookF, faQuestion, faStore, faStar, faPlay, faPause, faUndo, faChevronLeft, faChevronRight, faTimes)
Vue.config.productionTip = false
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueMasonryPlugin)
Vue.use(VueAwesomeSwiper, )
Vue.use(Zendesk, {
  key: 'fa263b90-a8bd-4490-b1e0-bdc86fb8f0d2',
  disabled: false,
  hideOnLoad: true,
  settings: {
    webWidget: {
      launcher: {
        mobile: {
          labelVisible: false
        }
      },
    }
  }
})
let mixpanelConfig = {
  token: '1a113e5fc5cca0dc79e4926f18ac8b8d',
  debug: true
}


Vue.use(VueMultianalytics, {
  modules: {
    mixpanel: mixpanelConfig
  }
})

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
