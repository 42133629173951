<template>
  <div class="page container" id="home" ref="mainSection" v-on:scroll="onScroll" v-bind:class="{ 'mobile' : $vuetify.breakpoint.smAndDown }">
      <v-row class="home-row page-inner">
        <v-col cols="12" sm="12" md="6" class="desc-box">
          <div class="desc-upper-box">
            <p class="main-title">나를 위한 <br/><strong>다이어트 코칭</strong> 챗봇</p>
            <img class="desc-logo" src="../assets/logo_en_w.svg" alt="logo" />
          </div>
          <div class="sub-title">
          </div>
          <div class="down-box" v-if="$vuetify.breakpoint.mdAndUp">
            <ul class="down-btns">
              <li class="down-btn appstore"><a href="#" @click.prevent="goAppStore"><img src="../assets/btn_apple@3x@2x.png" alt="appstore" /></a></li>
              <li class="down-btn google"><a href="#" @click.prevent="goPlayStore"><img src="../assets/btn_google@3x@2x.png" alt="google" /></a></li>
            </ul>
          </div>
          <div class="blank"></div>
          <div class="blank"></div>
        </v-col>
        <v-col cols="12" sm="12" md="6" class="lookup-box">
            <div class="mockup-box">
              <img class="mockup" src="../assets/main_mockup@2x.png" alt="mouckup" />
            </div>
          <div class="lookup-inner">
            <img class="ramhee" src="../assets/ramhee.png" alt="ramhee" />
            <ul class="msg-items">
              <li class="msg-item" v-html="msgList[0]"></li>
              <li class="msg-item" v-html="msgList[1]"></li>
              <li class="msg-item" v-html="msgList[2]"></li>
            </ul>
            <v-btn class="go-app" @click="goDeepLinkUrl"><img class="app-icon" src="../assets/app_icon@2x.png" alt="app_icon"/>람희 만나러 가기</v-btn>
          </div>
        </v-col>
      </v-row>
  </div>
</template>

<script>

  export default {
    name: 'Home',
    data: function () {
        return {
          deepLinkUrl: 'https://dietfriends.link/C35rDyW397',
          isMobile: false,
          deviceHeight: 0,
          msgList: [
              '어엇 혹시 람희를 찾아오신 건가요?',
              '혼자 외로웠는데 너무 기뻐요! ( ´▽`)',
              '다이어트를 도와줄 테니 람희의 친구가 되어주실래요?'
          ],
    }},
    created: function () {
        this.deviceHeight = document.documentElement.clientHeight;
        if (this.$vuetify.breakpoint.mobile) {
            window.addEventListener('scroll', this.onScroll);
            this.isMobile = true;
            if (this.deviceHeight < 600) {
              return false;
            }
        } else {
            setTimeout(()=> {
                this.$zendesk.show();
            }, 3000);
            this.isMobile = false;
            if (this.deviceHeight < 800 && this.deviceHeight > 700) {
              return false;
            } else if (this.deviceHeight <= 700) {
              return false;
            }
        }
    },
    mounted() {
        setTimeout(() => {
            setTimeout(() => { this.showMockup = true; }, 500);
        }, 1000);
    },
    destroyed() {
        window.removeEventListener('scroll', this.onScroll);
    },
    methods: {
      goDeepLinkUrl: function () {
        this.$ma.trackEvent({action: '딥링크 클릭 [홈:람희 만나러 가기]'});
        return window.open(this.deepLinkUrl, '_blank');
      },
      goAppStore: function () {
        this.$ma.trackEvent({action: '앱스토어 클릭 [홈]'});
        return window.open('https://apps.apple.com/kr/app/%EB%8B%A4%EC%9D%B4%EC%96%B4%ED%8A%B8-%ED%94%84%EB%A0%8C%EC%A6%88/id1466899980', '_blank');
      },
      goPlayStore: function () {
        this.$ma.trackEvent({action: '구글플레이 클릭 [홈]'});
        return window.open('https://play.google.com/store/apps/details?id=kr.dietfriends.android', '_blank');
      },
      onScroll: function () {
          let windowTop = this.$refs.mainSection.scrollTop;
          (windowTop -50) > this.deviceHeight ? this.showBanner = true : this.showBanner = false;
      },
    },
    components: {}
  }
</script>
<style lang="scss" scoped>
  @import '../variables.scss';

  #home {
    display: block;
    position: relative;
    height: 700px;
    .home-row {
      display: flex;
      position: relative;
      height: 700px;
      overflow: hidden;
      text-align: center;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .lookup-box {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 10;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      overflow: hidden;
      padding: 0;
      .mockup-box {
        position: absolute;
        width: 400px;
        height: auto;
        margin: auto;
        .mockup {
          width: 400px;
          height: auto;
          min-height: 434px;
          min-width: 200px;
          max-width: 400px;
          max-height: 938px;
          z-index: 99;
        }
      }
      .lookup-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        position: relative;
        z-index: 200;
        height: auto;
        max-height: 700px;
        width: 400px;
        padding: 0 0 1.6rem 0;

        .ramhee {
          width: auto;
          height: 190px;
          margin-top: 68px;
          margin-bottom: 24px;
        }

        .msg-items {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          padding: 0 48px;
          .msg-item {
            word-break: keep-all;
            padding: 1rem;
            margin-bottom: 1rem;
            display: block;
            width: auto;
            border: 1.5px solid $msgBorderColor;
            border-radius: 1px 20px 20px 20px;
            background: $lookUpChatBoxColor;
            font-family: $basicFont;
            text-align: left;
            font-size: 16px;
            line-height: 24px;
            font-weight: bold;
          }
        }

        .go-app {
          width: 284px;
          height: 74px;
          margin-top: 40px;
          border-radius: 37px;
          box-shadow: rgba(0,0,0,0.13) 0 5px 6px;
          background: $pointColor;
          font-size: 24px;
          font-family: $basicFont;
          letter-spacing: 0;
          font-weight: 700;
          line-height: 36px;

          .app-icon {
            width: 32px;
            height: 32px;
            margin-right: 10px;
          }
        }
      }
    }
    .desc-box {
      display: flex;
      flex: 1.1;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      align-items: flex-start;
      padding: 0;

      .desc-upper-box {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
      }
      .main-title {
        font-family: $basicFont;
        color: white;
        font-size: 52px;
        line-height: 72px;
        font-weight: normal;
        margin: 1rem 0 0 0;
        text-align: left;
      }
      .desc-logo {
        margin-top: 40px;
        height: 52px;
      }
      .down-box {
        margin-top: 110px;
        text-align: left;
        .down-btns {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0;

          .down-btn {
            width: 100%;
            text-align: left;
            margin-right: 20px;

            img {
              height: 72px;
              width: auto;
            }
          }
        }
      }

    }

  }
  #home.mobile {
    background: url('../assets/main_bg@2x.png') center center no-repeat;
    background-size: cover;
    height: auto !important;
    .home-row {
      height: auto !important;
    }
    .lookup-box {
      overflow: visible;
      justify-content: center;
      .mockup-box {
        width: 360px;
        .mockup {
          width: 360px;
          height: auto;
        }
      }
      .lookup-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        position: relative;
        z-index: 200;
        height: auto;
        max-height: 700px;
        width: 360px;
        padding: 0 0 1.6rem 0;

        .ramhee {
          width: auto;
          height: 120px;
          margin-top: 48px;
          margin-bottom: 24px;
        }

        .msg-items {
          padding: 0 24px;
          max-width: 90%;
        }

        .go-app {
          margin-top: 12px;
        }

      }
    }
    .desc-box {
      align-items: center;
      padding-top: 36px;
      padding-bottom: 64px;

      .main-title {
        font-family: $basicFont;
        color: white;
        font-size: 36px;
        line-height: 52px;
        font-weight: normal;
        margin: 1rem 0 0 0;
        text-align: left;
        strong {
          font-weight: normal;
        }
      }
      .desc-logo {
        margin-top: 40px;
        width: 100%;
        height: auto;
      }
      .desc-upper-box {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
      }

    }

  }
</style>
