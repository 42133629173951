<template>
  <div class="page" id="review" v-bind:class="{ 'mobile' : $vuetify.breakpoint.smAndDown }">
    <div class="review-body">
      <v-container class="body-inner">
        <v-row class="body-row page-inner">
          <v-col cols="12" md="4" sm="12" class="review-left score-box d-flex flex-column justify-center align-start">
            <div class="score-wrapper">
              <div class="title-box">
                <p class="page-title">사용자 평점</p>
                <p class="score">4.8점</p>
                <p class="score-base">/5점</p>
              </div>
              <div class="rating-box">
                <div class="ref-text">
                  <p>(앱스토어, 2020.07.11 기준, 446개의 평가)</p>
                </div>
                <v-row class="rate-row" v-for="rates in [5,4,3,2,1]" v-bind:key="rates">
                  <v-col class="left">
                    <font-awesome-icon :icon="['fas', 'star']" v-for="rate in rates" v-bind:key="rate"></font-awesome-icon>
                  </v-col>
                  <v-col class="right">
                    <div class="bar-container">
                      <div class="stat-bar" v-bind:class="['bar-' + rates, {show: !showStatBar}]" ></div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="8" sm="12" class="review-right tmn-box">
            <div class="tmn-box-inner">
              <div class="masonry-box row" v-masonry orgin-left="true" horizontal-order="true" transition-duration="0.3s" resize="true" item-selector=".review-card">
                <v-col class="review-card" cols="6" md="4" ref="reviews" v-masonry-tile v-for="(item, index) in reviews" v-bind:key="item" v-bind:index="index">
                  <a href="https://apps.apple.com/kr/app/%EB%8B%A4%EC%9D%B4%EC%96%B4%ED%8A%B8-%ED%94%84%EB%A0%8C%EC%A6%88/id1466899980#see-all/reviews" target="_blank"><img class="review-img" :src="getThumbUrl(index+1)" alt="review" /></a>
                </v-col>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
    export default {
        name: "Review",
        props: ['isReviewPageScrolled'],
        watch: {
            isReviewPageScrolled: function (isCurrent) {
                let target = this.$refs.reviews[1];
                if (isCurrent) {
                    this.isShowBg = true;
                    this.showStatBar = true;
                    target.classList.remove('col-6', 'col-md-4');
                    target.classList.add('col-12', 'col-md-8');
                    this.$redrawVueMasonry();
                    setTimeout(()=>{
                        target.classList.remove('col-12', 'col-md-8');
                        target.classList.add('col-6', 'col-md-4');
                        this.$redrawVueMasonry();
                    }, 2000)
                } else {
                    this.isShowBg = false;
                }
            }
        },
        data: function () {
            return {
                reviews: Array(15),
                isShowBg: false,
                showStatBar: false,
            }
        },
        created() {

        },
        mounted () {
            if (typeof this.$redrawVueMasonry === 'function') {
                this.$redrawVueMasonry()
            }
        },
        methods: {
            getThumbUrl(pic) {
                return require('../assets/reviews/'+pic+'.png')
            },
        },
    }
</script>

<style lang="scss" scoped>

  @import '../variables';

  #review {
    width: 100%;
    height: $basicSectionHeight;
    display: block;
    position: relative;

    .review-body {
      height: 100%;
      .body-inner {
        height: 100%;

      }
      .body-row {
        height: 100%;

        .score-box {
          height: 100%;
          width: 100%;
          padding: 0;

          .score-wrapper {
            width: 277px;
          }
          .title-box {
            width: 100%;
            text-align: left;
            margin-bottom: 32px;
            .page-title {
              font-size: 52px;
              height: 72px;
              font-family: $basicFont;
              font-weight: bold;
              color: black;
              margin-bottom: 26px;
            }
            .score {
              color: black;
              text-underline-position: under;
              text-align: left;
              font-size: 72px;
              line-height: 72px;
              margin: 0;
              font-family: $basicFont;
              font-weight: bold;
              display: inline-block;
            }
            .score-base {
              display: inline-block;
              margin: 0;
              font-size: 28px;
              line-height: 26px;
              color: black;
            }
          }
          .rating-box {
            display: flex;
            flex-direction: column;
            width: 100%;
            .rate-row {
              height: 18px;
              margin:0 0 20px 0;
            }
            .left{
              display: flex;
              justify-content: flex-start;
              padding: 0;
              svg {
                height: 18px;
                color: #F8821E;
              }
            }
            .right {
              padding: 0;
              display: flex;
              flex-direction: row;
              align-items: center;
            }
            .ref-text {
              min-width: 80%;
              text-align: left;
              p {
                font-size: 14px;
                line-height: 21px;
                font-family: $basicFont;
                margin-bottom: 14px;
                color: black;
              }
            }
          }
        }
        .tmn-box {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          padding-right: 0;

          .tmn-box-inner {
            height: 628px;
            width: 100%;
            padding: 0;
            overflow: hidden;
            position: relative;
          }

          .review-card {
            margin: 0;
            padding: 0 0.5rem 0.5rem 0.5rem;
            .review-img {
              border-radius: 0 8px 8px 8px;
              width: 100%;
              height: auto;
            }
          }
          .review-card:hover {
            .review-img {
              border: 1px solid #ee4643;
              transition: ease-in-out 0.1s;
            }
          }
        }
      }
    }

    /* The bar container */
    .bar-container {
      width: 159px;
      height: 12px;
      background-color: #F3F3F3;
      text-align: center;
      color: white;
    }

    /* Individual bars */
    .bar-5 {width: 1%; height: 12px; background-color: #5E5E5E;}
    .bar-4 {width: 1%; height: 12px; background-color: #5E5E5E;}
    .bar-3 {width: 1%; height: 12px; background-color: #5E5E5E;}
    .bar-2 {width: 1%; height: 12px; background-color: #5E5E5E;}
    .bar-1 {width: 1%; height: 12px; background-color: #5E5E5E;}
    .bar-5.show {width: 87%; height: 12px; background-color: #5E5E5E;}
    .bar-4.show {width: 7%; height: 12px; background-color: #5E5E5E;}
    .bar-3.show {width: 2%; height: 12px; background-color: #5E5E5E;}
    .bar-2.show {width: 1%; height: 12px; background-color: #5E5E5E;}
    .bar-1.show {width: 2%; height: 12px; background-color: #5E5E5E;}
    .stat-bar.show {
      transition: ease-in-out 0.8s;
    }

  }
  #review.mobile {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    padding: $mobileSectionPadding;

    .review-body {
      height: 100%;
      .body-inner {
        height: 100%;

      }
      .body-row {
        height: 100%;

        .score-box {
          height: 100%;
          width: 100%;
          padding: 0;
          align-items: center !important;
          margin-bottom: 72px;
          .score {
            color: black;
            text-underline-position: under;
            text-align: left;
            font-size: 52px;
            line-height: 52px;
            margin: 0;
            font-family: $basicFont;
            font-weight: bold;
            display: inline-block;
          }
          .score-base {
            display: inline-block;
            margin: 0;
            font-size: 28px;
            line-height: 26px;
            color: black;
          }
        }
        .tmn-box {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          padding: 0;

          .tmn-box-inner {
            height: 628px;
            width: 100%;
            padding: 0;
            overflow: hidden;
            position: relative;
          }

          .review-card {
            margin: 0;
            padding: 0 0.5rem 0.5rem 0.5rem;
            .review-img {
              border-radius: 0 8px 8px 8px;
              width: 100%;
              height: auto;
            }
          }
          .review-card:hover {
            .review-img {
              border: 1px solid #ee4643;
              transition: ease-in-out 0.1s;
            }
          }
        }
      }
    }

  }
</style>
