<template>
  <div id="reference-card" v-bind:class="{'mobile': $vuetify.breakpoint.smAndDown}">
    <v-container class="reference-container">
      <v-row class="ref-row page-inner">
        <div class="ref-box">
          <p class="ref-title">
            한국영양학회 기준에 따른<br/><strong>검증된 맞춤 정보 제공</strong>
          </p>
          <v-row class="ref-text-row">
            <span class="ref-logo-box"></span>
            <span class="ref-text-box">
              <p class="ref-text">
                밸런스 프렌즈는 한국영양학회의 연구와 <br/>권장사항을 기준으로 정보를 제공합니다.
              </p>
            </span>
          </v-row>
        </div>
        <div class="ref-obj-box" v-if="!$vuetify.breakpoint.smAndDown">
          <img class="ref-obj" src="../assets/ref-object@2x.png" alt="ref-obj" />
        </div>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: 'ReferenceCard',
  data: () => ({
  }),
  mounted() {
  },
  methods: {
  }
};
</script>
<style lang="scss" scoped>
@import '../variables';
#reference-card {
  background: #DCF4FF;
  .ref-row {
    overflow: visible;
    justify-content: center;
    align-items: center;
    height: 300px;
    position: relative;

    .ref-box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .ref-title {
        text-align: center;
        font-family: $basicFont;
        font-size: 40px;
        line-height: 60px;
        margin-bottom: 26px;
      }

      .ref-text-row {
        justify-content: center;
        align-items: center;

        .ref-logo-box {
          background: url('../assets/kns_logo@2x.png') center center no-repeat;
          background-size: cover;
          width: 95px;
          height: 34px;
          margin-right: 14px;
        }
        .ref-text {
          font-size: 14px;
          line-height: 21px;
          font-family: $basicFont;
          margin: 0;
          color: #616161;
        }
      }
    }
    .ref-obj-box {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 60px;
      margin: auto;
      width: 326px;
      height: 467px;
      transform: rotate(16deg);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      z-index: 999;

      .ref-obj {
        height: 424px;
        width: 218px;

        z-index: 999;
      }
    }
  }
}
#reference-card.mobile {
  background: #DCF4FF;
  padding: $mobileSectionPadding;

  .ref-row {
    overflow: visible;
    justify-content: center;
    align-items: center;
    height: auto;
    position: relative;

    .ref-box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .ref-title {
        text-align: center;
        font-family: $basicFont;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 22px;
      }
    }

    .ref-text-row {
      justify-content: center;
      align-items: center;

      .ref-logo-box {
        background: url('../assets/kns_logo@2x.png') center center no-repeat;
        background-size: cover;
        width: 95px;
        height: 34px;
        margin-right: 14px;
      }
      .ref-text {
        font-size: 12px;
        line-height: 21px;
        margin: 0;
        color: #616161;
      }
    }
  }
}
</style>
