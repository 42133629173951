<template>
  <div id="footer-banner" v-bind:class="{ 'mobile': $vuetify.breakpoint.smAndDown }">
    <v-container class="page-container">
      <div class="page-inner">
        <v-col class="page-col d-flex flex-column justify-center align-center">
          <div class="page-title-box">
            <p class="title-text" v-if="!$vuetify.breakpoint.smAndDown">나만의 다이어트 코칭 앱, 밸런스 프렌즈</p>
            <p class="title-text" v-if="$vuetify.breakpoint.smAndDown">나만의 다이어트 코칭 앱<br/> 밸런스 프렌즈</p>
            <p class="title-sub">지금 람희에게 다이어트 코칭을 받아보세요!</p>
          </div>
          <div class="down-box">
            <ul class="down-btns">
              <li class="down-btn appstore"><a href="#" @click.prevent="goAppStore"><img src="../assets/btn_apple@3x@2x.png" alt="appstore" /></a></li>
              <li class="down-btn google"><a href="#" @click.prevent="goPlayStore"><img src="../assets/btn_google@3x@2x.png" alt="google" /></a></li>
            </ul>
          </div>
        </v-col>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  name: 'FooterBanner',
  data: () => ({

  }),
  methods: {
    goAppStore: function () {
      this.$ma.trackEvent({action: '앱스토어 클릭 [하단]'});
      return window.open('https://apps.apple.com/kr/app/%EB%8B%A4%EC%9D%B4%EC%96%B4%ED%8A%B8-%ED%94%84%EB%A0%8C%EC%A6%88/id1466899980', '_blank');
    },
    goPlayStore: function () {
      this.$ma.trackEvent({action: '구글플레이 클릭 [하단]'});
      return window.open('https://play.google.com/store/apps/details?id=kr.dietfriends.android', '_blank');
    },
  }
}
</script>
<style lang="scss" scoped>
@import '../variables';
#footer-banner {
  height: 350px;
  width: 100%;
  background: $primaryBlueColor;

  .page-container,
  .page-inner,
  .page-col {
    height: 100%;
  }
  .page-title-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 44px;

    .title-sub {
      font-weight: normal;
      font-size: 24px;
      font-family: $basicFont;
      line-height: 42px;
      color: white;
      margin: 0;
    }
    .title-text {
      font-weight: bold;
      font-size: 40px;
      line-height: 60px;
      font-family: $basicFont;
      color: white;
      margin-bottom: 14px;
    }
  }
  .down-box {
    .down-btns {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .down-btn {
        display: block;
        a {
          float: left;
        }
        img {
          width: 212px;
          height: auto;
        }
      }
    }
  }
}
#footer-banner.mobile {
  height: auto;
  padding: $mobileSectionPadding;

  .page-title-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 44px;

    .title-sub {
      font-weight: normal;
      font-size: 16px;
      line-height: 32px;
      color: white;
      margin: 0;
      text-align: center;
    }
    .title-text {
      text-align: center;
      font-weight: bold;
      font-size: 28px;
      line-height: 52px;
      color: white;
      font-family: $basicFont;
      word-break: keep-all;
      margin-bottom: 14px;
    }
  }
  .down-box {
    .down-btns {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .down-btn {
        display: block;
        a {
          float: left;
        }
        img {
          width: 160px;
          height: auto;
        }
      }
    }
  }
}
</style>
